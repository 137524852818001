import classes from "./IndentSelect.module.css";

const IndentSelect = ({ indent, onChange }) => {
    return (
        <div className={classes.container} >
            <label for="indent-select" className={classes.indentSelectLabel}>Indent</label>
            <select name="indent" className={classes.indentSelect} id="indent-select" value={indent} onChange={onChange} >
                <option value={0}>0</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
            </select>
        </div>
    );
}

export default IndentSelect;
