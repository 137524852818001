import { useState, memo } from "react";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import Button from "@mui/material/Button";

import classes from "./CheckItem.module.css";
import InputField from "../../InputField/InputField";
import IndentSelect from "../IndentSelect/IndentSelect";

const CheckItem = memo((props) => {
  const [isDraggable, setIsDraggable] = useState(false);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  /**
   * Handles the onChange event for when the item input changes.
   * This function updates the item of this checkItem in the checklist state in 'ChecklistDetail.js'
   * @param {object} event
   */
  const handleItemUpdate = (event) => {
    props.onItemUpdate(
      props.subchecklistId,
      props.itemId,
      "item",
      event.target.value
    );
  };

  /**
   * Handles the onChange event for when the action input changes.
   * This function updates the action of this checkItem in the checklist state in 'ChecklistDetail.js'
   * @param {object} event
   */
  const handleActionUpdate = (event) => {
    props.onItemUpdate(
      props.subchecklistId,
      props.itemId,
      "action",
      event.target.value
    );
  };
  
  /**
   * Handles the onChange event for when the indent changes.
   * This function updates the item of this checklist state in 'ChecklistDetail.js'
   * @param {object} event
   */
  const handleItemIndentUpdate = (event) => {
      // Parse the string into an integer.
      const indentValue = parseInt(event.target.value, 10);
      props.onItemIndentUpdate(
          props.subchecklistId,
          props.itemId,
          indentValue
      );
  };

  // Handles the onClick event to delete the current checkItem from the checklist state in 'ChecklistDetail.js'
  const handleDeleteItem = () => {
    props.onDeleteItem(props.subchecklistId, props.itemId);
  };

  const handleToggleBold = () => {
    props.onToggleBold(props.subchecklistId, props.itemId);
  };

  const handleToggleItalic = () => {
    props.onToggleItalic(props.subchecklistId, props.itemId);
  };

  // Handles the mousedown event on the checkItem drag handle.
  const handleMouseDown = (event) => {
    setIsDraggable(true);
    event.stopPropagation();
  };

  // Handles the ondrag event for the checkItem.
  const handleDrag = (event) => {
    props.onDrag(props.itemId);
    event.stopPropagation();
  };

  // Handles the dragleave event for the checkItem.
  const handleDragLeave = (event) => {
    if (isDraggedOver) {
      event.stopPropagation();
      setIsDraggedOver(false);
    }
  };

  // Handles the dragover event for the checkItem (when another checkItem/condition is dragged over this checkItem).
  const handleDragOver = (event) => {
    if (!isDraggedOver) {
      event.stopPropagation();
      props.onDragOver(props.itemId);
      setIsDraggedOver(true);
    }
  };

  // Handles the dragend event when the user stops dragging the checkItem.
  const handleDragEnd = (event) => {
    if (isDraggable) {
      event.stopPropagation();
      setIsDraggable(false);
      props.onDragEnd();
      props.resetDraggedId();
    }
  };

  // Handles the mouseup event on the checkItem drag handle.
  const handleMouseUp = (event) => {
    setIsDraggable(false);
    event.stopPropagation();
  };

  return (
    <div
      id={props.id}
      className={
        isDraggedOver ? classes.checkItemDraggedOver : classes.checkItem
      }
      onDrag={handleDrag}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDragEnd={handleDragEnd}
      draggable={isDraggable}
    >
      <div className={classes.editor}>
        <span
          className={classes.handle}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
        >
          <DragHandleIcon />
        </span>

        <div className={classes.controls}>
          <span className={classes.checkItemForm}>
            <InputField
              label="Item"
              value={props.item}
              onChange={handleItemUpdate}
              minlength="1"
              maxlength="50"
            />
          </span>
          <span className={classes.checkItemForm}>
            <InputField
              label="Action"
              value={props.action}
              onChange={handleActionUpdate}
              minlength="1"
              maxlength="50"
            />
          </span>

          <IndentSelect indent={props.indent} onChange={handleItemIndentUpdate} />

          <ToggleButtonGroup>
            <ToggleButton
              value="bold"
              aria-label="bold"
              size="small"
              selected={props.isBold}
              onClick={handleToggleBold}
            >
              <FormatBoldIcon />
            </ToggleButton>
            <ToggleButton
              value="italic"
              aria-label="italic"
              size="small"
              selected={props.isItalic}
              onClick={handleToggleItalic}
            >
              <FormatItalicIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <div className={classes.deleteButton}>
        <Button
          color="danger"
          variant="contained"
          size="small"
          onClick={handleDeleteItem}
        >
          Delete
        </Button>
      </div>
    </div>
  );
});

export default CheckItem;
