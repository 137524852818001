import { useState, memo } from "react";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Button from "@mui/material/Button";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";

import InputField from "../../InputField/InputField";
import IndentSelect from "../IndentSelect/IndentSelect";
import classes from "./Condition.module.css";

const Condition = memo((props) => {
  const [isDraggable, setIsDraggable] = useState(false);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  // Handles the onChange event from the text input and sets the value in the respective condition in the checklist state in ChecklistDetail.js.
  const handleTextUpdate = (event) => {
    props.onItemUpdate(
      props.subchecklistId,
      props.itemId,
      "text",
      event.target.value
    );
  };

  // Handles the onClick event to delete a condition item. This updates the checklist state in ChecklistDetail.js.
  const handleDeleteItem = () => {
    props.onDeleteItem(props.subchecklistId, props.itemId);
  };

  const handleToggleBold = () => {
    props.onToggleBold(props.subchecklistId, props.itemId);
  };

  const handleItemIndentUpdate = (event) => {
    // Do nothing if type is postcondition (it doesnt have indent controls).
    if (props.type === "postcondition") {
        return;
    }
    // Parse the string into an integer.
    const indentValue = parseInt(event.target.value, 10);
    props.onItemIndentUpdate(
        props.subchecklistId,
        props.itemId,
        indentValue
    );
  }

  const handleToggleItalic = () => {
    props.onToggleItalic(props.subchecklistId, props.itemId);
  };

  // Handles the mousedown event on the condition drag handle.
  const handleMouseDown = (event) => {
    setIsDraggable(true);
    event.stopPropagation();
  };

  // Handles the ondrag event for the condition.
  const handleDrag = (event) => {
    props.onDrag(props.itemId);
    event.stopPropagation();
  };

  const handleDragLeave = () => {
    if (isDraggedOver) setIsDraggedOver(false);
  };

  // Handles the dragover event for the condition (when another checkItem/condition is dragged over this condition).
  const handleDragOver = (event) => {
    if (!isDraggedOver) {
      event.stopPropagation();
      props.onDragOver(props.itemId);
      setIsDraggedOver(true);
    }
  };

  // Handles the dragend event when the user stops dragging the condition.
  const handleDragEnd = (event) => {
    if (isDraggable) {
      event.stopPropagation();
      setIsDraggable(false);
      props.onDragEnd();
      props.resetDraggedId();
    }
  };

  // Handles the mouseup event on the condition drag handle.
  const handleMouseUp = (event) => {
    setIsDraggable(false);
    event.stopPropagation();
  };

  
  const indentSelector = <IndentSelect indent={props.indent} onChange={handleItemIndentUpdate} />;
  
  const type = props.type === "precondition" ? "precondition" : "postcondition";
  return (
    <div
      id={props.id}
      className={
        isDraggedOver ? classes.conditionDraggedOver : classes.condition
      }
      onDrag={handleDrag}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDragEnd={handleDragEnd}
      draggable={isDraggable}
    >
      <div className={classes.editor}>
        <span
          className={classes.handle}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
        >
          <DragHandleIcon />
        </span>
        <div className={classes.controls}>
          <span className={classes.conditionForm}>
            <InputField
              label={type === "precondition" ? "Precondition" : "Postcondition"}
              value={props.value}
              onChange={handleTextUpdate}
              minlength="1"
              maxlength="150"
            />
          </span>
          { type === "precondition" && indentSelector }
          <ToggleButtonGroup>
            <ToggleButton
              value="bold"
              aria-label="bold"
              size="small"
              selected={props.isBold}
              onClick={handleToggleBold}
            >
              <FormatBoldIcon />
            </ToggleButton>
            <ToggleButton
              value="italic"
              aria-label="italic"
              size="small"
              selected={props.isItalic}
              onClick={handleToggleItalic}
            >
              <FormatItalicIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <div>
        <Button
          variant="contained"
          color="danger"
          size="small"
          onClick={handleDeleteItem}
        >
          Delete
        </Button>
      </div>
    </div>
  );
});

export default Condition;
