import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import SplitPane from "react-split-pane";
import { Auth } from "@aws-amplify/auth";
import ScaleLoader from "react-spinners/ScaleLoader";
import axios from "axios";
import { v4 } from "uuid";

import PdfDisplay from "../PdfDisplay/PdfDisplay";
import ChecklistEditor from "./ChecklistEditor/ChecklistEditor";
import AuthContext from "../../store/auth-context";
import classes from "./Checklist.module.css";
import useMemoizedCallback from "../../hooks/useMemoizedCallback";
import checklistSchema from "../../validation/checklistSchema";
import { showError } from "../../utils/notifications";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import { handleAuthError, handleAxiosError } from "../../utils/errorHandlers";

/** Need to load checklist elements into html elements
 * Title should be at the top
 * Each subchecklist should be an "accordian" type element, which shows title and then contents when expanded
 */
const Checklist = (props) => {
  const [checklist, setChecklist] = useState({
    name: "",
    content: [],
  }); // Initialize with empty data (to stop error)
  const [alert, setAlert] = useState(false); // When set, the items will be reloaded
  const [alertPdf, setAlertPdf] = useState(false); // When set the pdf will be reloaded
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [inputToFocus, setInputToFocus] = useState({});
  const checklistId = props.id;

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  // Load the checklist from api
  useEffect(() => {
    const loadChecklist = async () => {
      try {
        setIsLoading(true);
        setAlert(false); // reset alert
        const session = await Auth.currentSession();
        const url = `${process.env.REACT_APP_CHECKLISTS_SERVICE_BASE_URL}/checklists/${checklistId}`;
        const config = {
          headers: {
            Authorization: `Bearer ${session.accessToken.jwtToken}`,
          },
        };
        axios
          .get(url, config)
          .then((res) => {
            if (res.data.error) {
              showError("Error", res.data.error);
              navigate("/checklists");
            } else {
              if (res.data.Item) {
                setChecklist(res.data.Item);
              } else {
                showError("Error", "Checklist not found");
                navigate("/checklists");
              }
              setIsLoading(false);
            }
          })
          .catch((err) => {
            setIsLoading(false);
            handleAxiosError(err);
          });
      } catch (err) {
        handleAuthError(err, navigate, authCtx);
        setIsLoading(false);
      }
    };
    loadChecklist();
  }, [alert, checklistId, authCtx, navigate]);

  // Focus newly added item
  useEffect(() => {
    // Nothing to focus so just return.
    if (JSON.stringify(inputToFocus) === "{}") return;

    // Try and find element, but if nothing found then return.
    const element = document.getElementById(inputToFocus.id);
    if (!element) return;

    // Based on item that was created, find and focus the new input.
    if (
      ["checkitem", "condition", "frequencyitem"].includes(inputToFocus.type)
    ) {
      // Find all inputs, and focus the first one.
      const inputs = element.getElementsByTagName("input");
      if (inputs.length >= 1) {
        inputs[0].select(); // Select focuses and highlights text.
      }
    } else if (
      ["subchecklist", "frequencybox", "sectiontitle", "textbox"].includes(
        inputToFocus.type
      )
    ) {
      // Find the element input, and focus it.
      const inputs = element.getElementsByTagName("input");
      if (inputs.length === 1) {
        inputs[0].select(); // Select focuses and highlights text.
      }
    }
    setInputToFocus({}); // Reset state.
  }, [inputToFocus, setInputToFocus]);

  /** Handle update to item in subchecklist
   * 'subchecklistId' is the id of the subchecklist that the item is in.
   * 'itemId' is the id of the item we want to update.
   * 'type' should either be 'item' (checkItem), 'action' (checkItem) or 'text' (condition).
   * 'value' is the value that will be set in the item.
   */
  const handleItemUpdate = useMemoizedCallback(
    (subchecklistId, itemId, type, value) => {
      // Loop through subchecklists to find the one with the matching 'subchecklistId'
      for (let i = 0; i < checklist.content.length; i++) {
        if (checklist.content[i].id === subchecklistId) {
          // This is the subchecklist with the id matching 'subchecklistId'
          const selectedSubchecklist = checklist.content[i];

          // Loop through the checkItems in the subchecklist to find the one whose id matches 'itemId'
          for (let j = 0; j < selectedSubchecklist.checkItems.length; j++) {
            if (selectedSubchecklist.checkItems[j].id === itemId) {
              // Create copy of current checklist state to modify
              let updatedChecklist = { ...checklist };
              let updatedCheckItems = [...checklist.content[i].checkItems];

              // Set correct value based on the 'type'
              if (type === "item") {
                if (updatedCheckItems[j].type === "checkItem") {
                  updatedCheckItems[j].item = value;
                }
              } else if (type === "action") {
                if (updatedCheckItems[j].type === "checkItem") {
                  updatedCheckItems[j].action = value;
                }
              } else if (type === "text") {
                if (
                  updatedCheckItems[j].type === "checkItemPrecondition" ||
                  updatedCheckItems[j].type === "checkItemPostcondition"
                ) {
                  updatedCheckItems[j].text = value;
                }
              }
              // Set the new state
              updatedChecklist.content[i].checkItems = updatedCheckItems;
              setChecklist(updatedChecklist);
            }
          }
        }
      }
    },
    [checklist]
  );

  const handleItemIndentUpdate = useMemoizedCallback((subchecklistId, itemId, indent) => {
        if ([0, 1, 2].indexOf(indent) === -1) {
            // Invalid indent value given.
            return;
        }
        
        // Loop through subchecklists to find the one with matching 'subchecklistId'.
        for (let i = 0; i < checklist.content.length; i++) {
            if (checklist.content[i].id === subchecklistId) {
                const selectedSubchecklist = checklist.content[i];

                // Loop through the checkItems in the subchecklist to find the one matching 'itemId'.
                for (let j = 0; j < selectedSubchecklist.checkItems.length; j++) {
                    if (selectedSubchecklist.checkItems[j].id === itemId) {
                        // Create copy of current checklist state to modify.
                        let updatedChecklist = { ...checklist };
                        let updatedCheckItems = [ ...checklist.content[i].checkItems ];

                        // Update checkItem indent.
                        updatedCheckItems[j].indent = indent;

                        // Set the new state
                        updatedChecklist.content[i].checkItems = updatedCheckItems;
                        setChecklist(updatedChecklist);
                    }
                }
            }
        }
  }, [checklist]);

  /**
   * Deletes the item with id 'itemId' in the subchecklist with id 'subchecklistId'.
   * @param {string} subchecklistId
   * @param {string} itemId
   */
  const handleDeleteItem = useMemoizedCallback(
    (subchecklistId, itemId) => {
      for (let i = 0; i < checklist.content.length; i++) {
        if (checklist.content[i].id === subchecklistId) {
          const selectedSubchecklist = checklist.content[i];
          for (let j = 0; j < selectedSubchecklist.checkItems.length; j++) {
            if (selectedSubchecklist.checkItems[j].id === itemId) {
              // Make copy of checkItems array so that react will see change in props
              let newCheckItems = [...checklist.content[i].checkItems];
              newCheckItems.splice(j, 1);
              let newChecklist = { ...checklist };
              newChecklist.content[i].checkItems = newCheckItems;
              setChecklist(newChecklist);
            }
          }
        }
      }
    },
    [checklist]
  );

  /**
   * Adds a checkItem to the subchecklist with id 'subchecklistId'.
   * @param {string} subchecklistId
   * @param {string} item
   * @param {string} action
   */
  const handleAddCheckItem = useMemoizedCallback(
    (subchecklistId, item, action) => {
      for (let i = 0; i < checklist.content.length; i++) {
        if (checklist.content[i].id === subchecklistId) {
          let newCheckItem = {
            id: v4(),
            type: "checkItem",
            item,
            action,
            bold: false,
            italic: false,
            indent: 0,
          };
          let newCheckItems = [...checklist.content[i].checkItems];
          newCheckItems.push(newCheckItem);
          let newChecklist = { ...checklist };
          newChecklist.content[i].checkItems = newCheckItems;
          setChecklist(newChecklist);
          setInputToFocus({ type: "checkitem", id: newCheckItem.id });
        }
      }
    },
    [checklist]
  );

  /**
   * Adds a condition to subchecklist with id 'subchecklistId'.
   * @param {string} subchecklistId
   * @param {string} type ("precondition" || "postcondition")
   * @param {string} text
   */
  const handleAddCondition = useMemoizedCallback(
    (subchecklistId, type, text) => {
      for (let i = 0; i < checklist.content.length; i++) {
        if (checklist.content[i].id === subchecklistId) {
          if (type === "precondition") {
            let newPrecondition = {
              id: v4(),
              type: "checkItemPrecondition",
              text,
              bold: false,
              italic: false,
              indent: 0,
            };
            let newCheckItems = [...checklist.content[i].checkItems];
            newCheckItems.push(newPrecondition);
            let newChecklist = { ...checklist };
            newChecklist.content[i].checkItems = newCheckItems;
            setChecklist(newChecklist);
            setInputToFocus({ type: "condition", id: newPrecondition.id });
          } else if (type === "postcondition") {
            let newPostcondition = {
              id: v4(),
              type: "checkItemPostcondition",
              text,
              bold: false,
              italic: false,
            };
            let newCheckItems = [...checklist.content[i].checkItems];
            newCheckItems.push(newPostcondition);
            let newChecklist = { ...checklist };
            newChecklist.content[i].checkItems = newCheckItems;
            setChecklist(newChecklist);
            setInputToFocus({ type: "condition", id: newPostcondition.id });
          }
        }
      }
    },
    [checklist]
  );

  /**
   * Adds a subchecklist with name 'title'.
   * @param {string} title
   */
  const handleAddSubchecklist = useMemoizedCallback(() => {
    const newSubchecklist = {
      id: v4(),
      type: "subchecklist",
      title: "Subchecklist",
      color: "#FF0000",
      checkItems: [],
    };
    let newSubchecklists = [...checklist.content];
    newSubchecklists.push(newSubchecklist);
    let newChecklist = { ...checklist };
    newChecklist.content = newSubchecklists;
    setChecklist(newChecklist);
    setInputToFocus({ type: "subchecklist", id: newSubchecklist.id });
  }, [checklist]);

  /**
   * Deletes the subchecklist with id 'subchecklistId'.
   * @param {string} subchecklistId
   */
  const handleDeleteSubchecklist = useMemoizedCallback(
    (subchecklistId) => {
      // Find the right subchecklist
      for (let i = 0; i < checklist.content.length; i++) {
        if (checklist.content[i].id === subchecklistId) {
          // Get copy of current checklist state and remove correct subchecklist from it.
          let newSubchecklists = [...checklist.content];
          newSubchecklists.splice(i, 1);
          let newChecklist = { ...checklist };
          newChecklist.content = newSubchecklists;

          // Set new state
          setChecklist(newChecklist);
        }
      }
    },
    [checklist]
  );

  /**
   * Updates the title of the subchecklist with id 'subchecklistId'.
   * @param {string} subchecklistId
   * @param {string} title
   */
  const handleUpdateSubchecklistTitle = useMemoizedCallback(
    (subchecklistId, title) => {
      // find the right subchecklist
      for (let i = 0; i < checklist.content.length; i++) {
        if (checklist.content[i].id === subchecklistId) {
          let updatedSubchecklist = { ...checklist.content[i] };
          updatedSubchecklist.title = title;
          let updatedChecklist = { ...checklist };
          updatedChecklist.content[i] = updatedSubchecklist;
          setChecklist(updatedChecklist);
        }
      }
    },
    [checklist]
  );

  // Adds a blank space object to the end of the checklist.
  const handleAddBlankSpace = useMemoizedCallback(() => {
    let newSubchecklists = [...checklist.content];
    const newBlankSpace = {
      id: v4(),
      type: "blankSpace",
      height: 50,
    };
    newSubchecklists.push(newBlankSpace);
    let newChecklist = { ...checklist };
    newChecklist.content = newSubchecklists;
    setChecklist(newChecklist);
  }, [checklist]);

  // Updates the height of a blank space object.
  const handleUpdateBlankSpace = useMemoizedCallback(
    (idOfBlankSpace, height) => {
      // Find index of blankSpace
      const index = [...checklist.content].findIndex(
        (item) => item.id === idOfBlankSpace
      );
      if (checklist.content[index].type === "blankSpace") {
        let updatedSubchecklists = [...checklist.content];
        updatedSubchecklists[index].height = height;
        let updatedChecklist = { ...checklist };
        updatedChecklist.content = updatedSubchecklists;
        setChecklist(updatedChecklist);
      } else {
        console.error(
          `Cannot update height for item of type ${checklist.content[index].type}`
        );
      }
    },
    [checklist]
  );

  // Adds a sectionTitle object to the checklist.
  const handleAddSectionTitle = useMemoizedCallback(() => {
    let updatedContent = [...checklist.content];
    const newSectionTitle = {
      id: v4(),
      type: "sectionTitle",
      title: "Section Title",
      color: "#11ccee",
    };
    updatedContent.push(newSectionTitle);
    let updatedChecklist = { ...checklist };
    updatedChecklist.content = updatedContent;
    setChecklist(updatedChecklist);
    setInputToFocus({ type: "sectiontitle", id: newSectionTitle.id });
  }, [checklist]);

  // Updates the section title.
  const handleUpdateSectionTitle = useMemoizedCallback(
    (id, title) => {
      const index = checklist.content.findIndex((item) => item.id === id);
      if (checklist.content[index].type === "sectionTitle") {
        let updatedContent = [...checklist.content];
        updatedContent[index].title = title;
        let updatedChecklist = { ...checklist };
        updatedChecklist.content = updatedContent;
        setChecklist(updatedChecklist);
      } else {
        console.error(
          `Cannot update section title for item of type ${checklist.content[index].type}`
        );
      }
    },
    [checklist]
  );

  const handleAddTextBox = useMemoizedCallback(() => {
    let updatedContent = [...checklist.content];
    const newTextBox = {
      id: v4(),
      type: "textBox",
      title: "Text Box",
      color: "#11ccee",
      markdown: "",
    };
    updatedContent.push(newTextBox);
    let updatedChecklist = { ...checklist };
    updatedChecklist.content = updatedContent;
    setChecklist(updatedChecklist);
    setInputToFocus({ type: "textbox", id: newTextBox.id });
  }, [checklist]);

  const handleUpdateTextBox = useMemoizedCallback(
    (id, markdown) => {
      const index = checklist.content.findIndex((item) => item.id === id);
      if (checklist.content[index].type === "textBox") {
        let updatedContent = [...checklist.content];
        updatedContent[index].markdown = markdown;
        let updatedChecklist = { ...checklist };
        updatedChecklist.content = updatedContent;
        setChecklist(updatedChecklist);
      } else {
        console.error(
          `Cannot update TextBox for item of type ${checklist.content[index].type}`
        );
      }
    },
    [checklist]
  );

  const handleAddColumnBreak = useMemoizedCallback(() => {
    let updatedContent = [...checklist.content];
    const newColumnBreak = {
      id: v4(),
      type: "columnBreak",
    };
    updatedContent.push(newColumnBreak);
    let updatedChecklist = { ...checklist };
    updatedChecklist.content = updatedContent;
    setChecklist(updatedChecklist);
  });

  /**
   * Updates the title of the checklist
   * @param {string} title
   */
  const handleUpdateChecklistTitle = useMemoizedCallback(
    (title) => {
      const newChecklist = { ...checklist };
      newChecklist.name = title;
      setChecklist(newChecklist);
    },
    [checklist]
  );

  /**
   * Updates the color of a subchecklist
   * @param {string} subchecklistId
   * @param {string} color
   */
  const handleChangeSubchecklistColor = useMemoizedCallback(
    (subchecklistId, color) => {
      const indexOfSubchecklist = [...checklist.content]
        .map((item) => item.id)
        .indexOf(subchecklistId);
      let updatedContent = [...checklist.content];
      updatedContent[indexOfSubchecklist].color = color;
      let updatedChecklist = { ...checklist };
      updatedChecklist.content = updatedContent;
      setChecklist(updatedChecklist);
    },
    [checklist]
  );

  // Adds a FrequencyBox to the checklist content array.
  const handleAddFrequencyBox = useMemoizedCallback(() => {
    const frequencyBox = {
      id: v4(),
      type: "frequencyBox",
      title: "Frequencies",
      color: "#11ccee",
      frequencies: [],
    };
    let updatedContent = [...checklist.content];
    updatedContent.push(frequencyBox);
    let updatedChecklist = { ...checklist };
    updatedChecklist.content = updatedContent;
    setChecklist(updatedChecklist);
    setInputToFocus({ type: "frequencybox", id: frequencyBox.id });
  }, [checklist]);

  // Adds a FrequencyItem to the specified FrequencyBox.
  const handleAddFrequencyItem = useMemoizedCallback(
    (idOfFrequencyBox, name, frequency) => {
      const newFrequencyItem = {
        id: v4(),
        name,
        frequency,
        bold: false,
        italic: false,
      };
      // Find index of FrequencyBox.
      const indexOfFrequencyBox = [...checklist.content].findIndex(
        (item) => item.id === idOfFrequencyBox
      );

      let updatedFrequencies = [
        ...checklist.content[indexOfFrequencyBox].frequencies,
      ];
      updatedFrequencies.push(newFrequencyItem);
      let updatedChecklist = { ...checklist };
      updatedChecklist.content[indexOfFrequencyBox].frequencies =
        updatedFrequencies;
      setChecklist(updatedChecklist);
      setInputToFocus({ type: "frequencyitem", id: newFrequencyItem.id });
    },
    [checklist]
  );

  // Updated the name of a FrequencyItem.
  const handleUpdateFrequencyItemName = useMemoizedCallback(
    (idOfFrequencyBox, idOfFrequencyItem, name) => {
      // Get index of FrequencyBox.
      const indexOfFrequencyBox = [...checklist.content].findIndex(
        (item) => item.id === idOfFrequencyBox
      );

      // Get index of FrequencyItem.
      const indexOfFrequencyItem = [
        ...checklist.content[indexOfFrequencyBox].frequencies,
      ].findIndex((item) => item.id === idOfFrequencyItem);

      // Get copy of frequencyItem in question and update its name.
      let updatedFrequencyItems = [
        ...checklist.content[indexOfFrequencyBox].frequencies,
      ];
      updatedFrequencyItems[indexOfFrequencyItem].name = name;

      // Get copy of checklist state and replace the current frequencyItem in question with the updated one.
      let updatedChecklist = { ...checklist };
      updatedChecklist.content[indexOfFrequencyBox].frequencies =
        updatedFrequencyItems;
      setChecklist(updatedChecklist);
    },
    [checklist]
  );

  // Updated the frequency of a FrequencyItem.
  const handleUpdateFrequencyItemFrequency = useMemoizedCallback(
    (idOfFrequencyBox, idOfFrequencyItem, frequency) => {
      // Find index of FrequencyBox.
      const indexOfFrequencyBox = [...checklist.content].findIndex(
        (item) => item.id === idOfFrequencyBox
      );

      // Find index of FrequencyItem.
      const indexOfFrequencyItem = [
        ...checklist.content[indexOfFrequencyBox].frequencies,
      ].findIndex((item) => item.id === idOfFrequencyItem);

      // Get copy of frequencyItem in question and update its name.
      let updatedFrequencyItems = [
        ...checklist.content[indexOfFrequencyBox].frequencies,
      ];
      updatedFrequencyItems[indexOfFrequencyItem].frequency = frequency;

      // Get copy of checklist state and replace the current frequencyItem in question with the updated one.
      let updatedChecklist = { ...checklist };
      updatedChecklist.content[indexOfFrequencyBox].frequencies =
        updatedFrequencyItems;
      setChecklist(updatedChecklist);
    },
    [checklist]
  );

  // Deletes a FrequencyItem.
  const handleDeleteFrequencyItem = useMemoizedCallback(
    (idOfFrequencyBox, idOfFrequencyItem) => {
      // Find index of FrequencyBox.
      const indexOfFrequencyBox = [...checklist.content].findIndex(
        (item) => item.id === idOfFrequencyBox
      );

      // Find index of FrequencyItem.
      const indexOfFrequencyItem = [
        ...checklist.content[indexOfFrequencyBox].frequencies,
      ].findIndex((item) => item.id === idOfFrequencyItem);

      let updatedFrequencyItems = [
        ...checklist.content[indexOfFrequencyBox].frequencies,
      ];
      updatedFrequencyItems.splice(indexOfFrequencyItem, 1);
      let updatedChecklist = { ...checklist };
      updatedChecklist.content[indexOfFrequencyBox].frequencies =
        updatedFrequencyItems;
      setChecklist(updatedChecklist);
    },
    [checklist]
  );

  const handleToggleBoldCheckItem = useMemoizedCallback(
    (idOfSubchecklist, idOfCheckItem) => {
      const indexOfSubchecklist = [...checklist.content].findIndex(
        (item) => item.id === idOfSubchecklist
      );

      const indexOfCheckItem = [
        ...checklist.content[indexOfSubchecklist].checkItems,
      ].findIndex((item) => item.id === idOfCheckItem);

      let updatedCheckItems = [
        ...checklist.content[indexOfSubchecklist].checkItems,
      ];
      // Toggle bold
      updatedCheckItems[indexOfCheckItem].bold = updatedCheckItems[
        indexOfCheckItem
      ].bold
        ? false
        : true;

      // Set new state
      let updatedChecklist = { ...checklist };
      updatedChecklist.content[indexOfSubchecklist].checkItems =
        updatedCheckItems;
      setChecklist(updatedChecklist);
    },
    [checklist]
  );

  const handleToggleBoldFrequencyItem = useMemoizedCallback(
    (idOfFrequencyBox, idOfFrequencyItem) => {
      // Find index of FrequencyBox.
      const indexOfFrequencyBox = [...checklist.content].findIndex(
        (item) => item.id === idOfFrequencyBox
      );

      // Find index of FrequencyItem.
      const indexOfFrequencyItem = [
        ...checklist.content[indexOfFrequencyBox].frequencies,
      ].findIndex((item) => item.id === idOfFrequencyItem);

      let updatedFrequencyItems = [
        ...checklist.content[indexOfFrequencyBox].frequencies,
      ];
      // Toggle bold
      updatedFrequencyItems[indexOfFrequencyItem].bold = updatedFrequencyItems[
        indexOfFrequencyItem
      ].bold
        ? false
        : true;

      // Set new state
      let updatedChecklist = { ...checklist };
      updatedChecklist.content[indexOfFrequencyBox].frequencies =
        updatedFrequencyItems;
      setChecklist(updatedChecklist);
    },
    [checklist]
  );

  const handleToggleItalicCheckItem = useMemoizedCallback(
    (idOfSubchecklist, idOfCheckItem) => {
      // Find subchecklist index.
      const indexOfSubchecklist = [...checklist.content].findIndex(
        (item) => item.id === idOfSubchecklist
      );
      // Find checkItem index.
      const indexOfCheckItem = [
        ...checklist.content[indexOfSubchecklist].checkItems,
      ].findIndex((item) => item.id === idOfCheckItem);

      let updatedCheckItems = [
        ...checklist.content[indexOfSubchecklist].checkItems,
      ];

      // Toggle italic
      updatedCheckItems[indexOfCheckItem].italic = updatedCheckItems[
        indexOfCheckItem
      ].italic
        ? false
        : true;

      // Update state
      let updatedChecklist = { ...checklist };
      updatedChecklist.content[indexOfSubchecklist].checkItems =
        updatedCheckItems;
      setChecklist(updatedChecklist);
    },
    [checklist]
  );

  const handleToggleItalicFrequencyItem = useMemoizedCallback(
    (idOfFrequencyBox, idOfFrequencyItem) => {
      // Find index of FrequencyBox.
      const indexOfFrequencyBox = [...checklist.content].findIndex(
        (item) => item.id === idOfFrequencyBox
      );

      // Find index of FrequencyItem.
      const indexOfFrequencyItem = [
        ...checklist.content[indexOfFrequencyBox].frequencies,
      ].findIndex((item) => item.id === idOfFrequencyItem);

      let updatedFrequencyItems = [
        ...checklist.content[indexOfFrequencyBox].frequencies,
      ];

      // Toggle italic
      updatedFrequencyItems[indexOfFrequencyItem].italic =
        updatedFrequencyItems[indexOfFrequencyItem].italic ? false : true;

      // Update state
      let updatedChecklist = { ...checklist };
      updatedChecklist.content[indexOfFrequencyBox].frequencies =
        updatedFrequencyItems;
      setChecklist(updatedChecklist);
    },
    [checklist]
  );

  const handleChangePageSize = useMemoizedCallback(
    (pageSize) => {
      const allowedValues = ["Letter", "1/2 Letter"];
      if (allowedValues.indexOf(pageSize) === -1) return;
      let updatedChecklist = { ...checklist };
      updatedChecklist.pageSize = pageSize;
      setChecklist(updatedChecklist);
    },
    [checklist]
  );

  const handleChangeChecklistStyle = useMemoizedCallback(
    (style) => {
      const allowedValues = ["Dots", "Table"];
      if (allowedValues.indexOf(style) === -1) return;
      let updatedChecklist = { ...checklist };
      updatedChecklist.checklistStyle = style;
      setChecklist(updatedChecklist);
    },
    [checklist]
  );

  const handleChangeNumberOfColumns = useMemoizedCallback(
    (numCols) => {
      const allowedValues = [1, 2, 3, 4, 5, 6];
      // Exit if the number given is not 1, 2, 3, 4, 5, 6.
      if (allowedValues.indexOf(numCols) === -1) return;
      let updatedChecklist = { ...checklist };
      updatedChecklist.columns = numCols;
      setChecklist(updatedChecklist);
    },
    [checklist]
  );

  const handleChangeFontSize = useMemoizedCallback(
    (fontSize) => {
      const allowedValues = [];
      for (let i = 5; i <= 25; i += 0.5) {
        // Add all values from 5 to 25 with 0.5 steps.
        allowedValues.push(i);
      }
      if (allowedValues.indexOf(fontSize) === -1) return;
      let updatedChecklist = { ...checklist };
      updatedChecklist.fontSize = fontSize;
      setChecklist(updatedChecklist);
    },
    [checklist]
  );

  const handleChangeFontFamily = useMemoizedCallback(
    (fontFamily) => {
      // I dont think we need to do validation here, already done on backend and this is coming from a select component.
      let updatedChecklist = { ...checklist };
      updatedChecklist.fontFamily = fontFamily;
      setChecklist(updatedChecklist);
    },
    [checklist]
  );

  /**
   * Sends an update (put) request to update the database to the current checklist state.
   */
  const handleSendUpdateRequest = useMemoizedCallback(async () => {
    try {
      setIsSaving(true);
      const url = `${process.env.REACT_APP_CHECKLISTS_SERVICE_BASE_URL}/checklists/${checklistId}`;
      const session = await Auth.currentSession();
      const config = {
        headers: {
          Authorization: `Bearer ${session.accessToken.jwtToken}`,
        },
      };
      const data = {
        name: checklist.name,
        pageSize: checklist.pageSize,
        checklistStyle: checklist.checklistStyle,
        columns: checklist.columns,
        fontSize: checklist.fontSize,
        fontFamily: checklist.fontFamily,
        content: checklist.content,
      };
      // Validate data
      const validationResult = checklistSchema.validate(data);
      if (validationResult.error) {
        console.error("Validation Error: ", validationResult.error);
        showError("Error", "Validation Error. Fix errors shown in red below.");
        setIsSaving(false);
        return;
      }

      axios
        .put(url, data, config)
        .then((res) => {
          if (res.data.error) {
            showError("Error", res.data.error);
          } else {
            setAlertPdf(true);
          }
          setIsSaving(false);
        })
        .catch((err) => {
          setIsSaving(false);
          handleAxiosError(err);
        });
    } catch (err) {
      handleAuthError(err, navigate, authCtx);
    }
  }, [checklist]);

  const handleReorderSubchecklists = useMemoizedCallback(
    /**
     * Handles the reordering of subchecklists for drag and drop.
     * @param {String} idOfDraggedSubchecklist
     * @param {String} idOfDestinationSubchecklist
     * @returns
     */
    (idOfDraggedSubchecklist, idOfDestinationSubchecklist) => {
      // If being dragged over itself, do nothing.
      if (idOfDraggedSubchecklist === idOfDestinationSubchecklist) return;
      // If one if the ids is empty, do nothing.
      else if (
        idOfDraggedSubchecklist === "" ||
        idOfDestinationSubchecklist === ""
      )
        return;

      // Order n method for finding the indexes of both subchecklists
      let indexOfDraggedSubchecklist = -1;
      let indexOfDestinationSubchecklist = -1;
      [...checklist.content].forEach((item, index) => {
        if (item.id === idOfDraggedSubchecklist)
          indexOfDraggedSubchecklist = index;
        else if (item.id === idOfDestinationSubchecklist)
          indexOfDestinationSubchecklist = index;
      });

      // If either subchecklist index was not found, return.
      if (
        indexOfDraggedSubchecklist === -1 ||
        indexOfDestinationSubchecklist === -1
      )
        return;

      const subchecklistToMove = {
        ...checklist.content[indexOfDraggedSubchecklist],
      };
      let updatedChecklist = { ...checklist };
      let updatedContent = [...checklist.content];

      if (indexOfDraggedSubchecklist > indexOfDestinationSubchecklist) {
        /* Add a copy of the subchecklist were moving to the index of the subchecklist where it was moved to,
      pushing the subchecklist it was moved to and all subchecklists after that forward. */
        updatedContent.splice(
          indexOfDestinationSubchecklist,
          0,
          subchecklistToMove
        );
        // Delete the original copy of the subchecklist that we moved from the location it was moved from (now it only exists in the new location)
        updatedContent.splice(indexOfDraggedSubchecklist + 1, 1);
      } else if (indexOfDraggedSubchecklist < indexOfDestinationSubchecklist) {
        /* Add a copy of the subchecklist were moving to one after the index of the subchecklist where it was moved to,
      pushing all subchecklists after that forward. */
        updatedContent.splice(
          indexOfDestinationSubchecklist + 1,
          0,
          subchecklistToMove
        );
        // Delete the original copy of the subchecklist that we moved from the location it was moved from (now it only exists in the new location)
        updatedContent.splice(indexOfDraggedSubchecklist, 1);
      }
      updatedChecklist.content = updatedContent;
      setChecklist(updatedChecklist);
    },
    [checklist]
  );

  /**
   * Handles the reordering of checkItems/conditions for drag and drop
   * @param {String} idOfSubchecklist
   * @param {String} idOfDraggedCheckItem
   * @param {String} idOfDestinationCheckItem
   * @returns
   */
  const handleReorderCheckItems = useMemoizedCallback(
    (idOfSubchecklist, idOfDraggedCheckItem, idOfDestinationCheckItem) => {
      // If being dragged over itself, do nothing.
      if (idOfDraggedCheckItem === idOfDestinationCheckItem) return;
      // If either of the ids are empty, do nothing.
      else if (idOfDraggedCheckItem === "" || idOfDestinationCheckItem === "")
        return;

      const indexOfSubchecklist = [...checklist.content].findIndex(
        (item) => item.id === idOfSubchecklist
      );

      // Order n method for finding the indexes of both checkItems
      let indexOfDraggedCheckItem = -1;
      let indexOfDestinationCheckItem = -1;
      [...checklist.content[indexOfSubchecklist].checkItems].forEach(
        (item, index) => {
          if (item.id === idOfDraggedCheckItem) indexOfDraggedCheckItem = index;
          else if (item.id === idOfDestinationCheckItem)
            indexOfDestinationCheckItem = index;
        }
      );

      // If either of the checkItems indexes werent found, return
      if (indexOfDraggedCheckItem === -1 || indexOfDestinationCheckItem === -1)
        return;

      // Get copy of subchecklist that contains the checkItems being reordered.
      const subchecklist = [...checklist.content][indexOfSubchecklist];

      if (indexOfDraggedCheckItem > indexOfDestinationCheckItem) {
        const draggedCheckItem = {
          ...subchecklist.checkItems[indexOfDraggedCheckItem],
        };

        let updatedCheckItems = [
          ...checklist.content[indexOfSubchecklist].checkItems,
        ];
        // Add copy of dragged checkItem to new destination
        updatedCheckItems.splice(
          indexOfDestinationCheckItem,
          0,
          draggedCheckItem
        );

        // Delete old original version of checkItem (its a duplicate now)
        updatedCheckItems.splice(indexOfDraggedCheckItem + 1, 1);
        let updatedChecklist = { ...checklist };
        updatedChecklist.content[indexOfSubchecklist].checkItems =
          updatedCheckItems;
        setChecklist(updatedChecklist);
      } else if (indexOfDraggedCheckItem < indexOfDestinationCheckItem) {
        const draggedCheckItem = {
          ...subchecklist.checkItems[indexOfDraggedCheckItem],
        };

        let updatedCheckItems = [...subchecklist.checkItems];

        // Add copy of dragged checkItem to new destination
        updatedCheckItems.splice(
          indexOfDestinationCheckItem + 1,
          0,
          draggedCheckItem
        );

        // Delete old original version of checkItem (its a duplicate now)
        updatedCheckItems.splice(indexOfDraggedCheckItem, 1);
        let updatedChecklist = { ...checklist };
        updatedChecklist.content[indexOfSubchecklist].checkItems =
          updatedCheckItems;
        setChecklist(updatedChecklist);
      }
    },
    [checklist]
  );

  const handleReorderFrequencyItems = useMemoizedCallback(
    /**
     * Handles the reorder of FrequencyItems in a FrequencyBox.
     * @param {String} idOfFrequencyBox
     * @param {String} idOfDraggedItem
     * @param {String} idOfDestinationItem
     * @returns
     */
    (idOfFrequencyBox, idOfDraggedItem, idOfDestinationItem) => {
      // If being dragged over itself, do nothing.
      if (idOfDraggedItem === idOfDestinationItem) return;
      // If either of the indexes are empty, do nothing.
      else if (idOfDraggedItem === "" || idOfDestinationItem === "") return;

      // Find index of FrequencyBox
      const indexOfFrequencyBox = [...checklist.content].findIndex(
        (item) => item.id === idOfFrequencyBox
      );

      // Order n method to find indexes of FrequencyItems.
      let indexOfDraggedItem = -1;
      let indexOfDestinationItem = -1;
      [...checklist.content[indexOfFrequencyBox].frequencies].forEach(
        (item, index) => {
          if (item.id === idOfDraggedItem) indexOfDraggedItem = index;
          else if (item.id === idOfDestinationItem)
            indexOfDestinationItem = index;
        }
      );

      // If either of the indexes werent found, return.
      if (indexOfDraggedItem === -1 || indexOfDestinationItem === -1) return;

      // Get copy of subchecklist that contains the checkItems being reordered.
      const frequencyBox = [...checklist.content][indexOfFrequencyBox];

      if (indexOfDraggedItem > indexOfDestinationItem) {
        const draggedItem = {
          ...frequencyBox.frequencies[indexOfDraggedItem],
        };

        let updatedItems = [
          ...checklist.content[indexOfFrequencyBox].frequencies,
        ];
        // Add copy of dragged checkItem to new destination
        updatedItems.splice(indexOfDestinationItem, 0, draggedItem);

        // Delete old original version of checkItem (its a duplicate now)
        updatedItems.splice(indexOfDraggedItem + 1, 1);
        let updatedChecklist = { ...checklist };
        updatedChecklist.content[indexOfFrequencyBox].frequencies =
          updatedItems;
        setChecklist(updatedChecklist);
      } else if (indexOfDraggedItem < indexOfDestinationItem) {
        const draggedItem = {
          ...frequencyBox.frequencies[indexOfDraggedItem],
        };

        let updatedItems = [...frequencyBox.frequencies];

        // Add copy of dragged checkItem to new destination
        updatedItems.splice(indexOfDestinationItem + 1, 0, draggedItem);

        // Delete old original version of checkItem (its a duplicate now)
        updatedItems.splice(indexOfDraggedItem, 1);
        let updatedChecklist = { ...checklist };
        updatedChecklist.content[indexOfFrequencyBox].frequencies =
          updatedItems;
        setChecklist(updatedChecklist);
      }
    },
    [checklist]
  );

  // SplitPane styles (should maybe put these into a different file)
  const resizerStyle = {
    width: "5px",
    background: "rgb(79, 79, 238)",
    cursor: "col-resize",
    flexShrink: 0,
  };
  const pane1Style = {
    overflow: "auto",
  };
  const pane2Style = {
    overflow: "auto",
  };

  const loadScreen = (
    <div className={classes.loading}>
      <ScaleLoader color="#4f4fee" />
    </div>
  );

  return (
    <div className={classes.container}>
      <SplitPane
        className={classes.splitpane}
        split="vertical"
        minSize={50}
        defaultSize={window.innerWidth / 2}
        pane1Style={pane1Style}
        pane2Style={pane2Style}
        resizerStyle={resizerStyle}
      >
        <ErrorBoundary>
          {!isLoading && (
            <ChecklistEditor
              name={checklist.name}
              id={checklist.id}
              pageSize={checklist.pageSize}
              checklistStyle={checklist.checklistStyle}
              columns={checklist.columns}
              fontSize={checklist.fontSize}
              logo={checklist.logo}
              fontFamily={checklist.fontFamily}
              subchecklists={checklist.content}
              onTitleChange={handleUpdateChecklistTitle}
              onPageSizeChange={handleChangePageSize}
              onChecklistStyleChange={handleChangeChecklistStyle}
              onColumnsChange={handleChangeNumberOfColumns}
              onChangeFontSize={handleChangeFontSize}
              onChangeFontFamily={handleChangeFontFamily}
              onSave={handleSendUpdateRequest}
              isSaving={isSaving}
              setAlert={setAlert}
              setAlertPdf={setAlertPdf}
              onAddSubchecklist={handleAddSubchecklist}
              onDeleteSubchecklist={handleDeleteSubchecklist}
              onUpdateSubchecklistTitle={handleUpdateSubchecklistTitle}
              onChangeSubchecklistColor={handleChangeSubchecklistColor}
              onReorderSubchecklists={handleReorderSubchecklists}
              onItemUpdate={handleItemUpdate}
              onItemIndentUpdate={handleItemIndentUpdate}
              onDeleteItem={handleDeleteItem}
              onAddCheckItem={handleAddCheckItem}
              onAddCondition={handleAddCondition}
              onReorderCheckItems={handleReorderCheckItems}
              onAddBlankSpace={handleAddBlankSpace}
              onUpdateBlankSpace={handleUpdateBlankSpace}
              onAddSectionTitle={handleAddSectionTitle}
              onUpdateSectionTitle={handleUpdateSectionTitle}
              onAddFrequencyBox={handleAddFrequencyBox}
              onUpdateFrequencyBoxTitle={handleUpdateSubchecklistTitle}
              onAddFrequencyItem={handleAddFrequencyItem}
              onDeleteFrequencyItem={handleDeleteFrequencyItem}
              onToggleBoldCheckItem={handleToggleBoldCheckItem}
              onToggleBoldFrequencyItem={handleToggleBoldFrequencyItem}
              onToggleItalicCheckItem={handleToggleItalicCheckItem}
              onToggleItalicFrequencyItem={handleToggleItalicFrequencyItem}
              onUpdateFrequencyItemName={handleUpdateFrequencyItemName}
              onUpdateFrequencyItemFrequency={
                handleUpdateFrequencyItemFrequency
              }
              onReorderFrequencyItems={handleReorderFrequencyItems}
              onAddTextBox={handleAddTextBox}
              onUpdateTextBox={handleUpdateTextBox}
              onAddColumnBreak={handleAddColumnBreak}
            />
          )}
          {isLoading && loadScreen}
        </ErrorBoundary>
        <ErrorBoundary>
          <PdfDisplay
            checklistId={checklistId}
            alert={alertPdf}
            setAlert={setAlertPdf}
            showZoomControls
          />
        </ErrorBoundary>
      </SplitPane>
    </div>
  );
};

export default Checklist;
